






import Vue from 'vue';
import StaffNotification from '../../components/staffnotification/StaffNotification.vue';
export default Vue.extend({
  name: 'StaffNotificationPage',
  components: {
    StaffNotification,
  },
});
